import type { ComponentType } from "react"
import { addPropertyControls, ControlType, RenderTarget } from "framer"

import { useCallback, useEffect, useMemo, useState } from "react"
import { updateProgressLesson as upl } from "https://framer.com/m/progress-OA2C.js"
import { useAuth } from "https://framer.com/m/hooks-cRhB.js"

import Placeholder from "https://framer.com/m/Placeholder-hsKY.js"

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function MaskAsCompleted(props) {
    /**
     * Properties
     */

    const { courseSlug, lessonSlug } = props
    /**
     * Checks
     */
    const isCanvas = RenderTarget.current() === RenderTarget.canvas
    const { valid, message } = validateProps(props)

    const { user, isLoggedIn, loading } = useAuth()

    const dispatchEvent = useCallback(async () => {
        if (!isCanvas && isLoggedIn) {
            await upl({ userId: user.uid, courseSlug, lessonSlug })
        } else {
            console.log("Evento de concluir aula disparado!")
        }
    }, [courseSlug, lessonSlug, isLoggedIn])

    if (isCanvas && !valid) {
        return (
            <Placeholder title={"Faltou algum paramêtro"} subtitle={message} />
        )
    }

    return <button onClick={dispatchEvent}>Concluir Aula</button>
}

/**
 * Validate Props
 */
function validateProps(props) {
    const { courseSlug, lessonSlug } = props
    if (courseSlug && lessonSlug) {
        return { valid: true }
    } else {
        let missingField = ""
        if (!courseSlug) missingField = "courseTitle"
        else if (!lessonSlug) missingField = "lessonTitle"
        return {
            valid: false,
            message: `Missing required field: ${missingField}`,
        }
    }
}

addPropertyControls(MaskAsCompleted, {
    courseSlug: {
        title: "Course Title",
        type: ControlType.String,
    },
    lessonSlug: {
        title: "Lesson Title",
        type: ControlType.String,
    },
})
